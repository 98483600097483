import { StepProps } from "./step-props";
import { StepWizardChildProps } from "react-step-wizard";
import { useForm } from "react-hook-form";
import { IPriceRequest } from "../../model/price-request";
import { ResponsiveCollapse } from "../../ui-components/responsive-collapse/responsive-collapse";
import { SummaryProps } from "./summary-props";
import { useLanguage } from "../../localisation/LanguageContext";

export const Contact = (
  props: StepProps & Partial<StepWizardChildProps> & SummaryProps
) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { t } = useLanguage();

  const onSubmit = (data: any) => {
    const priceRequest: Partial<IPriceRequest> = {
      contactRequest: {
        contact: {
          email: data.inputEmail,
          firstName: data.inputVoornaam,
          lastName: data.inputNaam,
        },
        message: data.inputMessage,
      },
    };
    props.formSubmit(priceRequest);
    props.nextStep?.();
  };

  return (
    <div className="container-fluid">
      <div id="responsief" className="row fullscreen">
        <div className="col-md-6 color-1 bg-5 alpha-8 gradient d-flex align-items-center">
          <div className="responsive-collapse">
            <ResponsiveCollapse
              title={t("contactTitle")}
              stap={t("stappen", {
                step: props.currentStep?.toString() || "",
                total: props.totalSteps?.toString() || "",
              })}
            >
              <div>
                <p className="card-text">{t("contactCard1")}</p>
                <p className="card-text">{t("contactCard2")}</p>
                <p className="card-text">{t("contactCard3")}</p>
              </div>
            </ResponsiveCollapse>
          </div>
        </div>
        <div className="col-md-5 ml-md-auto text-md-right d-flex align-items-center">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group-style">
              <input
                type="email"
                className="form-control-style"
                id="inputEmail"
                placeholder={`${t("contactEmail")} *`}
                {...register("inputEmail", {
                  required: true,
                })}
                aria-invalid={errors.inputEmail ? "true" : "false"}
              />
              {errors.inputEmail && (
                <span className="form-required-warning">
                  {t("contactEmailRequired")}
                </span>
              )}
            </div>
            <div className="form-group-style">
              <input
                type="text"
                className="form-control-style"
                id="inputVoornaam"
                placeholder={t("contactFirstname")}
                {...register("inputVoornaam", { required: false })}
                aria-invalid={errors.inputVoornaam ? "true" : "false"}
              />
            </div>
            <div className="form-group-style">
              <input
                type="text"
                className="form-control-style"
                id="inputNaam"
                placeholder={t("contactLastname")}
                {...register("inputNaam", {
                  required: false,
                })}
                aria-invalid={errors.inputNaam ? "true" : "false"}
              />
            </div>

            <div className="form-group-style">
              <textarea
                className="form-control-style"
                id="inputMessage"
                placeholder={t("contactExtraInfo")}
                rows={6}
                {...register("inputMessage", { required: false })}
                aria-invalid={errors.inputMessage ? "true" : "false"}
              />
            </div>

            <div className="float-end">
              <button
                className="btn btn-1 text-end"
                onClick={(event) => {
                  event.preventDefault();
                  props.previousStep?.();
                }}
              >
                {t("btnPrevious")}
              </button>
              <button className="btn btn-1 text-end" type="submit">
                {t("btnCalculate")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
