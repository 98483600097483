import { ImageCheckboxProps } from "./image-checkbox-props";
import React, { ChangeEvent, useState } from "react";
import { Item } from "./selection-value";
import { useLanguage } from "../../localisation/LanguageContext";

export const ImageCheckbox = <V, I extends Item>(
  props: ImageCheckboxProps<V, I>
) => {
  const { value, register, name, required } = props;
  const [selectedValue, setSelectedValue] = useState<typeof value | undefined>(
    undefined
  );
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.checked
      ? setSelectedValue(value)
      : setSelectedValue(undefined);
  };
  const { t } = useLanguage();

  return (
    <label className={selectedValue !== undefined ? "label-selected" : ""}>
      <div>
        <input
          type="checkbox"
          {...register(name, { required: required })}
          onChange={onChange}
        />
        <div>
          <div className="select-title">
            <span>{t(value.item().title)}</span>
            <span
              className={
                selectedValue === value
                  ? "checkbox-selected select-checkbox"
                  : "select-checkbox"
              }
            ></span>
          </div>
          <div className="select-subtitle">
            <span>{t(value.item().subtitle)}</span>
          </div>
        </div>
      </div>
    </label>
  );
};
