import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

interface LanguageContextProps {
  language: string;
  setLanguage: (lang: string) => void;
  t: (key: string, variables?: Record<string, string>) => string;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(
  undefined
);

interface LanguageProviderProps {
  children: ReactNode;
  defaultLanguage?: string;
  translationConfig: { [key: string]: any }; // Configurable language keys and file names
}

export const LanguageProvider = ({
  children,
  translationConfig,
  defaultLanguage = "en",
}: LanguageProviderProps) => {
  const [language, setLanguage] = useState(defaultLanguage);
  const [translations, setTranslations] = useState<{ [key: string]: string }>(
    {}
  );

  useEffect(() => {
    const loadTranslationsForLanguage = async () => {
      const translationData = translationConfig[language];
      console.log("translationData", translationData);
      setTranslations(translationData);
    };

    loadTranslationsForLanguage();
  }, [language, translationConfig]);

  const t = (key: string, variables?: Record<string, string>): string => {
    const template = translations[key] || key;
    return interpolate(template, variables || {});
  };

  const interpolate = (
    template: string,
    variables: Record<string, string>
  ): string => {
    return template.replace(/{{\s*([^}\s]+)\s*}}/g, (match, p1) => {
      const variable = p1.trim();
      return variables[variable] || match;
    });
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
