import React from "react";
import { ResponsiveCollapseProps } from "./responsive-collapse-props";
import "bootstrap/js/src/collapse.js";

export const ResponsiveCollapse = (props: ResponsiveCollapseProps) => {
  const { title, stap, children } = props;

  return (
    <div>
      <div className="d-none d-md-block" style={{ maxHeight: "90vh" }}>
        <h5>{stap}</h5>
        <h6>{title}</h6>
        {children}
      </div>
      <div className="d-md-none">
        <button
          className="accordion-button fa"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <span>
            <div>{stap}</div>
            <div>{title}</div>
            <i className="fa fa-chevron-circle-up"></i>
            <i className="fa fa-chevron-circle-down"></i>
          </span>
        </button>
        <div className="collapse" id="collapseExample">
          {children}
        </div>
      </div>
    </div>
  );
};
