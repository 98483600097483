export const Size = {
    NONE: 'NONE',
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    LARGE: 'LARGE',
    UNKNOWN: 'UNKNOWN'
} as const;

export type Size = typeof Size[keyof typeof Size]

