import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { StepWizardChildProps } from "react-step-wizard";
import { StepProps } from "./step-props";
import { IPriceRequest } from "../../model/price-request";
import { ImageRadiobuttonGroup } from "../../ui-components/image-radiobutton/image-radiobutton-group";
import { ResponsiveCollapse } from "../../ui-components/responsive-collapse/responsive-collapse";
import {
  Item,
  SelectionValue,
} from "../../ui-components/image-checkbox/selection-value";
import { Size } from "../../model/size";
import { ItemMatcher, matchItem } from "../../util/item-matcher";
import { SingleStepProp } from "./single-step-prop";
import { processSingleselectStep } from "../process-step";
import { ImageRadiobutton } from "../../ui-components/image-radiobutton/image-radiobutton";
import { useLanguage } from "../../localisation/LanguageContext";

type PriceRequestSizeValue = SelectionValue<Size, Item>;

const functionalityItems: ItemMatcher<string, PriceRequestSizeValue> = {
  SMALL: () => ({
    value: () => Size.SMALL,
    item: () => ({
      title: "nrUsersSmallTitle",
      subtitle: "nrUsersSmallDescription",
    }),
  }),
  MEDIUM: () => ({
    value: () => Size.MEDIUM,
    item: () => ({
      title: "nrUsersMediumTitle",
      subtitle: "nrUsersMediumDescription",
    }),
  }),
  LARGE: () => ({
    value: () => Size.LARGE,
    item: () => ({
      title: "nrUsersLargeTitle",
      subtitle: "nrUsersLargeDescription",
    }),
  }),
  UNKNOWN: () => ({
    value: () => Size.UNKNOWN,
    item: () => ({
      title: "nrUsersUnknownTitle",
      subtitle: "nrUsersUnknownDescription",
    }),
  }),
};

export const NrUsers = (
  props: StepProps & SingleStepProp & Partial<StepWizardChildProps>
) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { t } = useLanguage();

  const onSubmit = (data: Partial<IPriceRequest>) => {
    processSingleselectStep(
      { ...data, [group.name]: group.selectedValue },
      props,
      props.property,
      functionalityItems,
      t("summaryOverviewUsers")
    );
  };

  const group: ImageRadiobuttonGroup = new ImageRadiobuttonGroup(
    props.property,
    true,
    useState<string>("")
  );
  return (
    <div className="container-fluid">
      <div id="responsief" className="row fullscreen">
        <div className="col-md-6 color-1 bg-5 alpha-8 gradient d-flex align-items-center">
          <div className="responsive-collapse">
            <ResponsiveCollapse
              title={t("nrUsersTitle")}
              stap={t("stappen", {
                step: props.currentStep?.toString() || "",
                total: props.totalSteps?.toString() || "",
              })}
            >
              <div>
                <p className="card-text">{t("nrUsersCard1")}</p>
                <p className="card-text">{t("nrUsersCard2")}</p>
              </div>
            </ResponsiveCollapse>
          </div>
        </div>
        <div className="col-md-5 ml-md-auto text-center text-md-right d-flex align-items-center">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            {errors[group.name] && (
              <div className="error-message">
                <span>{t("errorRequired")}</span>
              </div>
            )}
            <div className={errors[group.name] ? "form-error" : ""}>
              {Object.keys(functionalityItems).map((key) => {
                return (
                  <ImageRadiobutton<Size, Item>
                    register={register}
                    key={key}
                    value={matchItem(functionalityItems)(key)}
                    group={group}
                  />
                );
              })}
            </div>
            <div className="float-end">
              <button
                className="btn btn-1 text-end"
                onClick={(event) => {
                  event.preventDefault();
                  props.previousStep?.();
                }}
              >
                {t("btnPrevious")}
              </button>
              <button className="btn btn-1 text-end" type="submit">
                {t("btnNext")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
