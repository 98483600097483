import {FieldValues} from "react-hook-form";
import {StepProps} from "./steps/step-props";
import {StepWizardChildProps} from "react-step-wizard";
import {StepResult} from "./price-request-wizard";
import {ItemMatcher, matchItem} from "../util/item-matcher";
import {Item, SelectionValue} from "../ui-components/image-checkbox/selection-value";
import {IPricerequestKey} from "./steps/single-step-prop";
import { IPriceRequest } from "../model/price-request";


// const filteredItems = (items:Item[], item: Item) => items.filter(value => value.title === item.title);

export const processMultiselectStep = <V, I extends Item>(data: FieldValues,
                                                          props: StepProps & Partial<StepWizardChildProps>,
                                                          functionalityItems: ItemMatcher<string, SelectionValue<V, I>>,
                                                          category: string) => {
    const stepResult: StepResult = Object.keys(data).filter(value => data[value]).map(key => matchItem(functionalityItems)(key))
        .map(item => item)
        .reduce<StepResult>((acc, current) => {
            return {
                priceRequest: {...acc.priceRequest, ...current.value()},
                items: [...acc.items, current.item()]
            };
        }, {priceRequest: {}, items: [], values: []} as StepResult);
    ;

    props.formSubmit(stepResult.priceRequest);
    props.addItem?.(stepResult.items, category);
    props.nextStep?.();
}

export const processSingleselectStep = <V, I extends Item>(data: FieldValues,
                                                           props: StepProps & Partial<StepWizardChildProps>,
                                                           key: IPricerequestKey,
                                                           functionalityItems: ItemMatcher<string, SelectionValue<V, I>>,
                                                           category: string,
                                                           callback?: (priceRequest: IPriceRequest) => void) => {
    props.formSubmit(data, callback);
    const matchedItem = matchItem(functionalityItems)(data[key.toString()]);
    props.addItem?.([matchedItem.item()], category);
    props.nextStep?.();
}
