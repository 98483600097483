export const translation = {
  stappen: "Step {{step}} of {{total}}",
  btnNext: "Next",
  btnPrevious: "Previous",
  btnCalculate: "Calculate price",
  errorRequired: "Please select a value",
  defaultScreensTitle: "How many forms do you require?",
  defaultScreensCard1: "A form is an interactive component of a web application that allows users to enter and save data, such as personal information, feedback, or orders.",
  defaultScreensCard2: "It ensures a structured and user-friendly way of gathering information. It includes various elements such as dropdown menus, checkboxes, radio buttons, and text fields to allow for different types of input.",
  defaultScreensCard3: "For each input form, an overview screen is also provided to easily look up the entered data.",
  defaultScreensNoneTitle: "No forms",
  defaultScreensNoneDescription: "Suitable for a simple headless application.",
  defaultScreensSmallTitle: "1 - 5 forms",
  defaultScreensSmallDescription: "Suitable for a small application.",
  defaultScreensMediumTitle: "5 - 10 forms",
  defaultScreensMediumDescription: "Suitable for a medium sized application.",
  defaultScreensLargeTitle: "More than 10 forms",
  defaultScreensLargeDescription: "Suitable for a large application.",
  defaultScreensUnknownTitle: "I don't know",
  defaultScreensUnknownDescription: "No worries, we'll refine this later.",
  advancedScreensTitle: "How many advanced screen views do you need?",
  advancedScreensCard1: "This includes all aspects of the software that go beyond a simple input form.",
  advancedScreensCard2: "Think of advanced screen views such as calendar view, schedule overviews, map view, GIS functionality, and so on.",
  advancedScreensCard3: "Advanced workflows, such as assigning tasks to users, performing calculations, or sending notifications, also fall under this category.",
  advancedScreensNoneTitle: "No other screen views",
  advancedScreensNoneDescription: "Suitable for a simple application",
  advancedScreensSmallTitle: "1 - 5 screen views",
  advancedScreensSmallDescription: "Suitable for a small application",
  advancedScreensMediumTitle: "5 to 10 screen views",
  advancedScreensMediumDescription: "Suitable for a medium-sized application",
  advancedScreensLargeTitle: "More than 10 screen views",
  advancedScreensLargeDescription: "Suitable for a large application",
  advancedScreensUnknownTitle: "I don't know",
  advancedScreensUnknownDescription: "No worries, we'll refine this later",
  reportingTitle: "How many dashboards or reports do you need?",
  reportingCard1: "A dashboard is a visual representation of the data in your application.",
  reportingCard2: "Think of pie charts, bar charts, line charts, which can be clicked through if necessary.",
  reportingNoneTitle: "No reporting or dashboards",
  reportingNoneDescription: "Suitable for a headless application or an application without input forms",
  reportingSmallTitle: "1 - 5 dashboards or reports",
  reportingSmallDescription: "Suitable for a small application",
  reportingMediumTitle: "5 to 10 dashboards or reports",
  reportingMediumDescription: "Suitable for a medium-sized application",
  reportingLargeTitle: "More than 10 dashboards or reports",
  reportingLargeDescription: "Suitable for a large application",
  reportingUnknownTitle: "I don't know",
  reportingUnknownDescription: "No worries, we'll refine this later",
  otherFunctionalitiesTitle: "What other functionalities does your application require?",
  otherFunctionalitiesCard1: "An application typically has some functionalities that go beyond a simple input form or screen.",
  otherFunctionalitiesCard2: "It is possible, for example, to send an email when a certain event occurs or to build an integration with another system.",
  otherFunctionalitiesDocumentManagementTitle: "Upload files/photos",
  otherFunctionalitiesDocumentManagementDescription: "File and photo management",
  otherFunctionalitiesCameraTitle: "Camera",
  otherFunctionalitiesCameraDescription: "Use the camera of a mobile device",
  otherFunctionalitiesEmailTitle: "Email",
  otherFunctionalitiesEmailDescription: "Automatically send emails",
  otherFunctionalitiesIntegrationsTitle: "Integrations",
  otherFunctionalitiesIntegrationsDescription: "Integrations with other systems",
  otherFunctionalitiesDiversTitle: "Other functionalities",
  otherFunctionalitiesDiversDescription: "Various other functionalities",
  nrUsersTitle: "How many users will use your application?",
  nrUsersCard1: "It represents the total number of people who have created an account and have permission to log in and use the various functions of the system.",
  nrUsersCard2: "In other words, it tells us how many individual users are registered and can actually use the system.",
  nrUsersSmallTitle: "0 - 5 users",
  nrUsersSmallDescription: "There are a few active user accounts",
  nrUsersMediumTitle: "5 to 50 users",
  nrUsersMediumDescription: "There are 5 to 50 active user accounts",
  nrUsersLargeTitle: "More than 50 users",
  nrUsersLargeDescription: "There are more than 50 active user accounts",
  nrUsersUnknownTitle: "I don't know",
  nrUsersUnknownDescription: "No worries, we'll refine this later",
  hostingTitle: "How will the application be made available?",
  hostingCard1: "You can choose to make your application available and maintained online by us, or to do this on your own infrastructure.",
  hostingCard2: "If it is a critical application that needs to be available 7/7 and 24h/24h, additional measures can be taken.",
  hostingSimpleTitle: "Hosting by All-Ct",
  hostingSimpleDescription: "The applications are available online",
  hostingCriticalTitle: "Business-critical hosting by All-Ct",
  hostingCriticalDescription: "It is important that the applications are available 7/7 and 24/24",
  hostingOnPremiseTitle: "On-premise",
  hostingOnPremiseDescription: "The application is installed on your own servers",
  hostingUnknownTitle: "I don't know",
  hostingUnknownDescription: "No worries, we'll refine this later",
  contactTitle: "Additional information",
  contactCard1: "We're almost there!",
  contactCard2: "Receive an immediate and non-binding price indication by simply leaving your contact details.",
  contactCard3: "Do you want to tell us more about your project? We would love to hear all the details that are important to you.",
  contactEmail: "Your email address",
  contactFirstname: "Your first name",
  contactLastname: "Your last name",
  contactExtraInfo: "Additional information about your project",
  summaryTitle: "ESTIMATED COST OF YOUR APPLICATION",
  summaryCard1: "The estimated cost for your business application is {{minPrice}} to {{maxPrice}} EUR.",
  summaryInterested: "Interested? ", 
  summaryCard2: "Then book an appointment online, and we'll be happy to assist you with a detailed quote.",
  summaryCardQuestions: "Do you have questions? ",
  summaryCard3: "Don't hesitate to contact us.",
  summaryCard4: "Note: The estimate from the price calculator is only a rough estimate and is for indication purposes only. For a detailed quote, it is necessary to make an appointment with our team.",
  summaryCardInnecurate: "You have selected 'I don't know' for 1 or more questions. As a result, the provided price estimate is less accurate.",
  btnMakeAppointment: "Book an appointment",
  btnContact: "Contact",
  contactUsTitle: "Contact us",
  summaryOverview: "OVERVIEW OF YOUR APPLICATION",
  summaryOverviewScreens: "Default input forms",
  summaryOverviewAdvancedScreens: "Advanced screen views",
  summaryOverviewReporting: "Reporting",
  summaryOverviewFunctionalities: "Functionalities",
  summaryOverviewUsers: "Number of users",
  summaryOverviewHosting: "Hosting"
}

