import { ImageRadiobuttonProps } from "./image-radiobutton-props";
import { Item } from "../image-checkbox/selection-value";
import { ChangeEvent } from "react";
import { useLanguage } from "../../localisation/LanguageContext";

export const ImageRadiobutton = <V extends string | number, I extends Item>(
  props: ImageRadiobuttonProps<V, I>
) => {
  const { value, register, group } = props;
  // const [selectedValue, setSelectedValue] = useState<typeof value | undefined>(undefined);
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    group.onCheck(event.target.value);
  };

  const { t } = useLanguage();

  return (
    <label
      className={group.selectedValue === value.value() ? "label-selected" : ""}
    >
      <div>
        <input
          type="radio"
          value={value.value()}
          {...register(group.name, { required: group.required })}
          onChange={onChange}
        />
        {props.children}
        <div>
          <div className="select-title">
            <span>{t(value.item().title)}</span>
            <span
              className={
                group.selectedValue === value.value()
                  ? "checkbox-selected select-checkbox"
                  : "select-checkbox"
              }
            ></span>
          </div>
          <div className="select-subtitle">
            <span>{t(value.item().subtitle)}</span>
          </div>
        </div>
      </div>
    </label>
  );
};
