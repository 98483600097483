import { useEffect, useState } from "react";
import { StepProps } from "./step-props";
import { StepWizardChildProps } from "react-step-wizard";
import { SummaryProps } from "./summary-props";
import { Item } from "../../ui-components/image-checkbox/selection-value";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { IPriceQuotation } from "../../model/price-quotation";
import { ContactFormReact } from "@luyckxch/contact-form";
import { useLanguage } from "../../localisation/LanguageContext";

export const Summary = (
  props: StepProps & Partial<StepWizardChildProps> & SummaryProps
) => {
  const { priceRequest, items } = props;
  const [isShow, invokeModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [unknownItems, setUnknownItems] = useState(false);
  const [priceQuotation, setPriceQuotation] = useState<IPriceQuotation>({});
  const initModal = () => {
    return invokeModal(!isShow);
  };
  const { t, language } = useLanguage();

  const setConversion = (send_to: string) => {
    if ((window as any)["analyticsAllowed"]()) {
      (window as any)["gtag"]("event", "conversion", {
        send_to: send_to,
      });
    }
  };

  const contactFormSubmitted = (data: any) => {
    setConversion("AW-1027567779/pJ1UCLC13u4YEKPh_ekD");
  };

  useEffect(() => {
    if (props.isActive) {
      console.log(priceRequest);
      axios
        .post("/api/price-calculations", priceRequest)
        .then((response) => {
          setPriceQuotation(response.data);
          setConversion("AW-1027567779/gYU0CLO13u4YEKPh_ekD");
        })
        .catch((error) => {
          setIsError(true);
          console.error(error);
        });
    }
    setUnknownItems(
      Object.values(priceRequest).filter((value) => value === "UNKNOWN")
        .length > 0
    );
  }, [priceRequest, props.isActive]);

  return (
    <div className="container-fluid">
      {isError ? (
        <div className="alert alert-warning">{t("errorCalculation")}</div>
      ) : (
        <div id="responsief" className="row fullscreen">
          <div className="col-md-6 text-md-right d-flex align-items-center">
            <div
              className="card bg-light"
              style={{ width: "100%", marginTop: "5px" }}
            >
              <div className="card-body align-items-center summary-overview">
                <div>
                  <h6 className="card-title text-uppercase py-2">
                    {t("summaryTitle")}
                  </h6>
                  <p>
                    {t("summaryCard1", {
                      minPrice: new Intl.NumberFormat("en-us", {
                        minimumFractionDigits: 2,
                      }).format(priceQuotation.minPrice || 0),
                      maxPrice: new Intl.NumberFormat("en-us", {
                        minimumFractionDigits: 2,
                      }).format(priceQuotation.maxPrice || 0),
                    })}
                  </p>
                  {unknownItems &&
                    priceQuotation.maxPrice &&
                    priceQuotation.minPrice &&
                    (priceQuotation.maxPrice - priceQuotation.minPrice) /
                      priceQuotation.minPrice >
                      0.5 && (
                      <div className="alert alert-warning d-flex" role="alert">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                          viewBox="0 0 16 16"
                          role="img"
                          aria-label="Warning:"
                        >
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        <div>
                          <p>{t("summaryCardInnecurate")}</p>
                        </div>
                      </div>
                    )}
                  <p>
                    <span>{t("summaryInterested")} </span>
                    <span>{t("summaryCard2")}</span>
                  </p>
                  <p>
                    <span>{t("summaryCardQuestions")} </span>
                    <span>{t("summaryCard3")}</span>
                  </p>
                  <p>{t("summaryCard4")}</p>
                  <div className="float-end" style={{ display: "flex" }}>
                    <a
                      className="btn btn-1 text-end"
                      style={{ margin: "10px" }}
                      href="https://outlook.office365.com/owa/calendar/AllCtcontact@all-ct.com/bookings/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="d-md-none d-lg-inline">
                        {t("btnMakeAppointment")}
                      </span>
                    </a>
                    <button
                      className="btn btn-1 text-end"
                      style={{ margin: "10px" }}
                      type="button"
                      onClick={initModal}
                    >
                      {t("btnContact")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 ml-md-auto text-md-right d-flex align-items-center">
            <div className="card bg-light" style={{ marginTop: "5px" }}>
              <div className="card-body summary-overview">
                <h5 className="card-title text-uppercase text-truncate py-2">
                  {t("summaryOverview")}
                </h5>
                <div className="items border border-light">
                  <div className="items border border-light">
                    {[...items.entries()]
                      .filter(
                        ([key, items]: [string, Item[]]) =>
                          items && items.length > 0
                      )
                      .map(([key, items]: [string, Item[]]) => (
                        <div>
                          <div
                            className="card draggable shadow-sm"
                            id="cd1"
                            draggable="true"
                          >
                            <div className="card-body p-2">
                              <div className="card-title">
                                <span className="lead font-weight-light">
                                  {key}
                                </span>
                              </div>
                              {items.map((item) => (
                                <div className="summary-item">
                                  <h5>{t(item.title)}</h5>
                                  <span className={"summary-subitem"}>
                                    {t(item.subtitle)}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="dropzone rounded"> &nbsp; </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div onClick={(e) => e.stopPropagation()}>
            <Modal
              show={isShow}
              dialogClassName={"contactDialog"}
              contentClassName={"contactDialog"}
            >
              <Modal.Header closeButton onClick={initModal}>
                <Modal.Title>{t("contactUsTitle")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ContactFormReact
                  url="/api/contact-requests"
                  contactFormSubmitted={contactFormSubmitted}
                  language={language}
                />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};
