export const translation = {
  stappen: "Stap {{step}} van {{total}}",
  btnNext: "Volgende",
  btnPrevious: "Vorige",
  btnCalculate: "Bereken prijs",
  errorRequired: "Gelieve een waarde te selecteren",
  defaultScreensTitle: "Hoeveel invulformulieren heeft u nodig?",
  defaultScreensCard1: "Een invulformulier is een interactief onderdeel van een webapplicatie waarmee gebruikers gegevens kunnen invoeren en opslaan, zoals persoonlijke gegevens, feedback of bestellingen.",
  defaultScreensCard2: "Het zorgt voor een gestructureerde en gebruikersvriendelijke manier van informatie verzamelen. Het bevat diverse elementen, zoals dropdown-menu's, selectievakjes, keuzerondjes en tekstvelden, om verschillende soorten invoer mogelijk te maken.",
  defaultScreensCard3: "Voor iedere invulformulier wordt ook een overzichtsscherm voorzien om de ingevoerde gegevens makkelijk te kunnen opzoeken.",
  defaultScreensNoneTitle: "Geen standaard invulformulieren",
  defaultScreensNoneDescription: "Geschikt voor een headless applicatie",
  defaultScreensSmallTitle: "1 - 5 formulieren",
  defaultScreensSmallDescription: "Geschikt voor kleine applicatie",
  defaultScreensMediumTitle: "5 tot 10 formulieren",
  defaultScreensMediumDescription: "Geschikt voor een middelgrote applicatie",
  defaultScreensLargeTitle: "Meer dan 10 formulieren",
  defaultScreensLargeDescription: "Geschikt voor een grote applicatie",
  defaultScreensUnknownTitle: "Ik weet het niet",
  defaultScreensUnknownDescription: "Geen nood, we verfijnen dit later wel",
  advancedScreensTitle: "Hoeveel geavanceerde schermweergaven heeft u nodig?",
  advancedScreensCard1: "Dit omvat alle aspecten van de software die verder gaan dan een eenvoudig invulformulier.",
  advancedScreensCard2: "Denk hierbij aan geavanceerde schermweergaven zoals kalenderweergave, planningsoverzichten, kaartweergave, GIS-functionaliteit, enzovoort.",
  advancedScreensCard3: "Ook geavanceerde workflows, zoals het toewijzen van taken aan gebruikers, het uitvoeren van berekeningen of het versturen van meldingen vallen onder deze categorie.",
  advancedScreensNoneTitle: "Geen andere schermweergaven",
  advancedScreensNoneDescription: "Geschikt voor een eenvoudige applicatie",
  advancedScreensSmallTitle: "1 - 5 schermweergaven",
  advancedScreensSmallDescription: "Geschikt voor een kleine applicatie",
  advancedScreensMediumTitle: "5 tot 10 schermweergaven",
  advancedScreensMediumDescription: "Geschikt voor een middelgrote applicatie",
  advancedScreensLargeTitle: "Meer dan 10 schermweergaven",
  advancedScreensLargeDescription: "Geschikt voor een grote applicatie",
  advancedScreensUnknownTitle: "Ik weet het niet",
  advancedScreensUnknownDescription: "Geen nood, we verfijnen dit later wel",
  reportingTitle: "Hoeveel dashboards of rapporten heeft u nodig?",
  reportingCard1: "Een dashboard is een visuele weergave van de gegevens in uw applicatie.",
  reportingCard2: "Denk daarbij een taartdiagram, staafdiagram, lijndiagram, waarop eventueel kan doorgeklikt worden ... .",
  reportingNoneTitle: "Geen rapportering of dashboards",
  reportingNoneDescription: "Geschikt voor een headless applicatie of een applicatie zonder invulformulieren",
  reportingSmallTitle: "1 - 5 dashboards of rapporten",
  reportingSmallDescription: "Geschikt voor een kleine applicatie",
  reportingMediumTitle: "5 tot 10 dashboards of rapporten",
  reportingMediumDescription: "Geschikt voor een middelgrote applicatie",
  reportingLargeTitle: "Meer dan 10 dashboards of rapporten",
  reportingLargeDescription: "Geschikt voor een grote applicatie",
  reportingUnknownTitle: "Ik weet het niet",
  reportingUnknownDescription: "Geen nood, we verfijnen dit later wel",
  otherFunctionalitiesTitle: "Welke andere functionaliteiten vereist uw applicatie?",
  otherFunctionalitiesCard1: "Een applicatie heeft typisch ook enkele functionaliteiten die verder gaan dan een invulformulier of scherm.",
  otherFunctionalitiesCard2: "Het is mogelijk om bijvoorbeeld een e-mail uit te sturen wanneer een bepaalde gebeurtenis zich voordoet, of om een integratie te bouwen met een ander systeem.",
  otherFunctionalitiesDocumentManagementTitle: "Opladen bestanden / foto's",
  otherFunctionalitiesDocumentManagementDescription: "Beheer van bestanden en foto's",
  otherFunctionalitiesCameraTitle: "Camera",
  otherFunctionalitiesCameraDescription: "Gebruik de camera van een mobiel apparaat",
  otherFunctionalitiesEmailTitle: "E-mail",
  otherFunctionalitiesEmailDescription: "Automatisch versturen van e-mails",
  otherFunctionalitiesIntegrationsTitle: "Integraties",
  otherFunctionalitiesIntegrationsDescription: "Integraties met andere systemen",
  otherFunctionalitiesDiversTitle: "Andere functionaliteiten",
  otherFunctionalitiesDiversDescription: "Diverse andere functionaliteiten",
  nrUsersTitle: "Hoeveel gebruikers zullen uw applicatie gebruiken?",
  nrUsersCard1: "Het geeft het totale aantal mensen weer die een account hebben aangemaakt en toestemming hebben om in te loggen en de verschillende functies van het systeem te gebruiken.",
  nrUsersCard2: "Met andere woorden, het vertelt ons hoeveel individuele gebruikers er geregistreerd zijn en het systeem daadwerkelijk kunnen gebruiken.",
  nrUsersSmallTitle: "0 - 5 gebruikers",
  nrUsersSmallDescription: "Er zijn enkele actieve gebruikersaccounts",
  nrUsersMediumTitle: "5 tot 50 gebruikers",
  nrUsersMediumDescription: "Er zijn 5 tot 50 actieve gebruikersaccounts",
  nrUsersLargeTitle: "Meer dan 50 gebruikers",
  nrUsersLargeDescription: "Er zijn meer dan 50 actieve gebruikersaccounts",
  nrUsersUnknownTitle: "Ik weet het niet",
  nrUsersUnknownDescription: "Geen nood, we verfijnen dit later wel",
  hostingTitle: "Hoe wordt de applicatie ter beschikking gesteld?",
  hostingCard1: "U kunt ervoor kiezen om uw applicatie door ons online beschikbaar te stellen en te onderhouden, of om dit op uw eigen infrastructuur te doen.",
  hostingCard2: "Indien het om een kritische applicatie gaat die 7/7 en 24h/24h beschikbaar moet zijn, kunnen er extra maatregelen worden genomen.",
  hostingSimpleTitle: "Hosting door All-Ct",
  hostingSimpleDescription: "De applicaties zijn online beschikbaar",
  hostingCriticalTitle: "Bedrijfskritische hosting door All-Ct",
  hostingCriticalDescription: "Het is belangrijk dat de applicaties 7/7 en 24/24 beschikbaar zijn",
  hostingOnPremiseTitle: "On premise",
  hostingOnPremiseDescription: "De toepassing wordt op uw eigen servers geïnstalleerd",
  hostingUnknownTitle: "Ik weet het niet",
  hostingUnknownDescription: "Geen nood, we verfijnen dit later wel",
  contactTitle: "Extra informatie",
  contactCard1: "We zijn er bijna!",
  contactCard2: "Ontvang onmiddellijk en vrijblijvend een prijsindicatie door simpelweg je contactgegevens achter te laten.",
  contactCard3: "Wil je ons nog meer vertellen over je project? We horen graag alle details die belangrijk zijn voor jou.",
  contactEmail: "Uw e-mail adres",
  contactEmailRequired: "Gelieve uw e-mailadres op te geven",
  contactFirstname: "Uw voornaam",
  contactLastname: "Uw achternaam",
  contactExtraInfo: "Extra informatie over uw project",
  errorCalculation: "Er is een fout opgetreden bij het berekenen van de prijs. We verontschuldigen ons voor dit ongemak.",
  summaryTitle: "Geschatte kost van uw applicatie",
  summaryCard1: "De geschatte kost voor uw bedrijfstoepassing is {{minPrice}} tot {{maxPrice}} EUR.",
  summaryInterested: "Interesse? ",
  summaryCard2: "Boek dan online een afspraak en we helpen u graag verder met een concrete offerte.",
  summaryCardQuestions: "Heeft u vragen?",
  summaryCard3: "Aarzel dan niet om contact met ons op te nemen.",
  summaryCard4: "Let op: de raming van de prijscalculator is slechts een ruwe schatting en dient enkel ter indicatie. Voor een gedetailleerde offerte is het noodzakelijk om een afspraak te maken met ons team.",
  summaryCardInnecurate: "U hebt voor 1 of meerdere vragen gekozen voor 'Ik weet het niet'. De opgegeven prijsindicatie is daardoor minder nauwkeurig.",
  btnMakeAppointment: "Boek een afspraak",
  btnContact: "Contact",
  contactUsTitle: "Contacteer ons",
  summaryOverview: "OVERZICHT VAN UW APPLICATIE",
  summaryOverviewScreens: "Standaard invulformulieren",
  summaryOverviewAdvancedScreens: "Geavanceerde schermweergaven",
  summaryOverviewReporting: "Rapportering",
  summaryOverviewFunctionalities: "Functionaliteiten",
  summaryOverviewUsers: "Aantal gebruikers",
  summaryOverviewHosting: "Hosting"
}
