import React, { useState } from "react";
import StepWizard from "react-step-wizard";
import { defaultValue, IPriceRequest } from "../model/price-request";
import { OtherFunctionalities } from "./steps/other-functionalities";
import { NrUsers } from "./steps/nr-users";
import { Hosting } from "./steps/hosting";
import { Summary } from "./steps/summary";
import { Item } from "../ui-components/image-checkbox/selection-value";
import { AdvancedScreens } from "./steps/advanced-screens";
import { Dashboards } from "./steps/dashboards";
import { Contact } from "./steps/contact";
import { DefaultScreens } from "./steps/default-screens";

export type StepResult = {
  priceRequest: Partial<IPriceRequest>;
  items: Item[];
};

export const PriceRequestWizard = () => {
  const [statePriceRequest, updateStatePriceRequest] =
    useState<IPriceRequest>(defaultValue);
  const [stateItems, updateStateItems] = useState<Map<string, Item[]>>(
    new Map()
  );

  const formSubmit = (
    priceRequest: Partial<IPriceRequest>,
    callback?: (priceRequest: IPriceRequest) => void
  ) => {
    const data = { ...statePriceRequest, ...priceRequest };
    updateStatePriceRequest(data);
    if (callback) {
      callback(data);
    }
  };

  const addItem = (items: Item[], category: string) => {
    updateStateItems(stateItems.set(category, items));
  };

  return (
    <div className="b b-b">
      <StepWizard>
        <DefaultScreens
          formSubmit={formSubmit}
          addItem={addItem}
          property={"nrDefaultScreens"}
        />
        <AdvancedScreens
          formSubmit={formSubmit}
          addItem={addItem}
          property={"nrAdvancedScreens"}
        />
        <Dashboards
          formSubmit={formSubmit}
          addItem={addItem}
          property={"nrReportingDashboards"}
        />
        <OtherFunctionalities formSubmit={formSubmit} addItem={addItem} />
        <NrUsers
          formSubmit={formSubmit}
          addItem={addItem}
          property={"nrUsers"}
        />
        <Hosting
          formSubmit={formSubmit}
          addItem={addItem}
          property={"hosting"}
          priceRequest={statePriceRequest}
          items={stateItems}
        />
        <Contact
          formSubmit={formSubmit}
          priceRequest={statePriceRequest}
          items={stateItems}
        />
        <Summary
          formSubmit={formSubmit}
          priceRequest={statePriceRequest}
          items={stateItems}
        />
      </StepWizard>
    </div>
  );
};
