import { StepProps } from "./step-props";
import { StepWizardChildProps } from "react-step-wizard";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { ImageRadiobutton } from "../../ui-components/image-radiobutton/image-radiobutton";
import { ImageRadiobuttonGroup } from "../../ui-components/image-radiobutton/image-radiobutton-group";
import { IPriceRequest } from "../../model/price-request";
import { ResponsiveCollapse } from "../../ui-components/responsive-collapse/responsive-collapse";
import {
  Item,
  SelectionValue,
} from "../../ui-components/image-checkbox/selection-value";
import { ItemMatcher, matchItem } from "../../util/item-matcher";
import { HostingPlan } from "../../model/hosting";
import { processSingleselectStep } from "../process-step";
import { SingleStepProp } from "./single-step-prop";
import axios from "axios";
import { SummaryProps } from "./summary-props";
import { useLanguage } from "../../localisation/LanguageContext";

type PriceRequestHostingPlan = SelectionValue<HostingPlan, Item>;

const hostingItems: ItemMatcher<string, PriceRequestHostingPlan> = {
  SIMPLE: () => ({
    value: () => HostingPlan.SIMPLE,
    item: () => ({
      title: "hostingSimpleTitle",
      subtitle: "hostingSimpleDescription",
    }),
  }),
  CRITICAL: () => ({
    value: () => HostingPlan.CRITICAL,
    item: () => ({
      title: "hostingCriticalTitle",
      subtitle: "hostingCriticalDescription",
    }),
  }),
  ONPREMISE: () => ({
    value: () => HostingPlan.ONPREMISE,
    item: () => ({
      title: "hostingOnPremiseTitle",
      subtitle: "hostingOnPremiseDescription",
    }),
  }),
  UNKNOWN: () => ({
    value: () => HostingPlan.UNKNOWN,
    item: () => ({
      title: "hostingUnknownTitle",
      subtitle: "hostingUnknownDescription",
    }),
  }),
};
export const Hosting = (
  props: StepProps &
    SingleStepProp &
    Partial<StepWizardChildProps> &
    SummaryProps
) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isActive } = props;
  const [isError, setIsError] = useState(false);
  const { t } = useLanguage();

  const savePriceRequest = async (priceRequest: IPriceRequest) => {
    if (isActive) {
      if (!priceRequest?.id) {
        axios
          .post("/api/price-requests", priceRequest)
          .then((response) => {
            props.formSubmit(response.data);
          })
          .catch((error) => {
            console.error(error);
            setIsError(true);
          });
      } else {
        axios
          .put(`/api/price-requests/${priceRequest.id}`, priceRequest)
          .then((response) => {
            props.formSubmit(response.data);
          })
          .catch((error) => {
            console.error(error);
            setIsError(true);
          });
      }
    }
  };
  const onSubmit = (data: Partial<IPriceRequest>) => {
    processSingleselectStep(
      { ...data, [group.name]: group.selectedValue },
      props,
      props.property,
      hostingItems,
      t("summaryOverviewHosting"),
      savePriceRequest
    );
  };

  const group: ImageRadiobuttonGroup = new ImageRadiobuttonGroup(
    props.property,
    true,
    useState<string>("")
  );
  return (
    <div className="container-fluid">
      {isError ? (
        <div className="alert alert-warning">{t("errorCalculation")}</div>
      ) : (
        <div id="responsief" className="row fullscreen">
          <div className="col-md-6 color-1 bg-5 alpha-8 gradient d-flex align-items-center">
            <div className="responsive-collapse">
              <ResponsiveCollapse
                title={t("hostingTitle")}
                stap={t("stappen", {
                  step: props.currentStep?.toString() || "",
                  total: props.totalSteps?.toString() || "",
                })}
              >
                <div>
                  <p className="card-text">{t("hostingCard1")}</p>
                  <p className="card-text">{t("hostingCard2")}</p>
                </div>
              </ResponsiveCollapse>
            </div>
          </div>
          <div className="col-md-5 ml-md-auto text-center text-md-right d-flex align-items-center">
            <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
              {errors[group.name] && (
                <div className="error-message">
                  <span>{t("errorRequired")}</span>
                </div>
              )}
              <div>
                {Object.keys(hostingItems).map((key) => {
                  return (
                    <ImageRadiobutton<HostingPlan, Item>
                      register={register}
                      key={key}
                      value={matchItem(hostingItems)(key)}
                      group={group}
                    />
                  );
                })}
              </div>
              <div className="float-end">
                <button
                  className="btn btn-1 text-end"
                  onClick={(event) => {
                    event.preventDefault();
                    props.previousStep?.();
                  }}
                >
                  {t("btnPrevious")}
                </button>
                <button className="btn btn-1 text-end" type="submit">
                  {t("btnNext")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
