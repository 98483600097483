import { translation as translation_en } from "./locales/translation_en";
import { translation as translation_nl } from "./locales/translation_nl";
import { LanguageProvider } from "./localisation/LanguageContext";
import { PriceRequestWizard } from "./wizard/price-request-wizard";

// declare global {
//     namespace JSX {
//         interface IntrinsicElements {
//             'contact-form': {url: string};
//         }
//     }
// }

const translationConfig = {
  en: translation_en,
  nl: translation_nl,
};

function isValidLang(lang: string | null | undefined) {
  if (lang && translationConfig.hasOwnProperty(lang)) {
    return lang;
  }
  return undefined;
}

const searchParams = new URLSearchParams(document.location.search);
const lang =
  isValidLang(searchParams.get("lang")) ||
  isValidLang(navigator.language?.slice(0, 2)) ||
  "en";

function App() {
  return (
    <LanguageProvider
      translationConfig={translationConfig}
      defaultLanguage={lang}
    >
      <PriceRequestWizard />
    </LanguageProvider>
  );
}

export default App;
