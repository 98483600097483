import { Size } from "./size";
import { HostingPlan } from "./hosting";
import { IContactRequest } from "./contact-request";

export interface IPriceRequest {
  id?: number;
  dateSubmitted?: string | null;
  description?: string;
  webapplication: boolean;
  nrDefaultScreens: Size;
  nrAdvancedScreens: Size;
  nrReportingDashboards: Size;
  otherFunctionalities: boolean;
  fileUpload: boolean;
  camera: boolean;
  eMail: boolean;
  integrations: boolean;
  nrUsers: Size;
  hosting: HostingPlan;
  contactRequest?: IContactRequest | null;
}

export const defaultValue: Readonly<IPriceRequest> = {
  camera: false,
  otherFunctionalities: false,
  fileUpload: false,
  hosting: "UNKNOWN",
  integrations: false,
  nrUsers: "SMALL",
  nrDefaultScreens: "UNKNOWN",
  nrAdvancedScreens: "UNKNOWN",
  nrReportingDashboards: "UNKNOWN",
  webapplication: true,
  eMail: false,
};
