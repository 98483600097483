import { StepProps } from "./step-props";
import { StepWizardChildProps } from "react-step-wizard";
import { FieldValues, useForm } from "react-hook-form";
import { ImageCheckbox } from "../../ui-components/image-checkbox/image-checkbox";
import { ResponsiveCollapse } from "../../ui-components/responsive-collapse/responsive-collapse";
import { IPriceRequest } from "../../model/price-request";
import { ItemMatcher, matchItem } from "../../util/item-matcher";
import {
  Item,
  PriceRequestSelectionValue,
} from "../../ui-components/image-checkbox/selection-value";
import { processMultiselectStep } from "../process-step";
import { useLanguage } from "../../localisation/LanguageContext";

const functionalityItems: ItemMatcher<string, PriceRequestSelectionValue> = {
  fileUpload: () => ({
    value: () => ({ fileUpload: true }),
    item: () => ({
      title: "otherFunctionalitiesDocumentManagementTitle",
      subtitle: "otherFunctionalitiesDocumentManagementDescription",
    }),
  }),
  camera: () => ({
    value: () => ({ camera: true }),
    item: () => ({
      title: "otherFunctionalitiesCameraTitle",
      subtitle: "otherFunctionalitiesCameraDescription",
    }),
  }),
  eMail: () => ({
    value: () => ({ eMail: true }),
    item: () => ({
      title: "otherFunctionalitiesEmailTitle",
      subtitle: "otherFunctionalitiesEmailDescription",
    }),
  }),
  integrations: () => ({
    value: () => ({ integrations: true }),
    item: () => ({
      title: "otherFunctionalitiesIntegrationsTitle",
      subtitle: "otherFunctionalitiesIntegrationsDescription",
    }),
  }),
  otherFunctionalities: () => ({
    value: () => ({ otherFunctionalities: true }),
    item: () => ({
      title: "otherFunctionalitiesDiversTitle",
      subtitle: "otherFunctionalitiesDiversDescription",
    }),
  }),
};

export const OtherFunctionalities = (
  props: StepProps & Partial<StepWizardChildProps>
) => {
  const { register, handleSubmit } = useForm();
  const { t } = useLanguage();

  const onSubmit = (data: FieldValues) => {
    processMultiselectStep(
      data,
      props,
      functionalityItems,
      t("summaryOverviewFunctionalities")
    );
  };

  return (
    <div className="container-fluid">
      <div id="responsief" className="row fullscreen">
        <div className="col-md-6 color-1 bg-5 alpha-8 gradient d-flex align-items-center">
          <div className="responsive-collapse">
            <ResponsiveCollapse
              title={t("otherFunctionalitiesTitle")}
              stap={t("stappen", {
                step: props.currentStep?.toString() || "",
                total: props.totalSteps?.toString() || "",
              })}
            >
              <div>
                <p className="card-text">{t("otherFunctionalitiesCard1")}</p>
                <p className="card-text">{t("otherFunctionalitiesCard2")}</p>
              </div>
            </ResponsiveCollapse>
          </div>
        </div>
        <div className="col-md-5 ml-md-auto text-center text-md-right d-flex align-items-center">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <div>
              {Object.keys(functionalityItems).map((key) => {
                return (
                  <ImageCheckbox<Partial<IPriceRequest>, Item>
                    register={register}
                    key={key}
                    name={key}
                    value={matchItem(functionalityItems)(key)}
                    required={false}
                  />
                );
              })}
            </div>
            <div className="float-end">
              <button
                className="btn btn-1 text-end"
                onClick={(event) => {
                  event.preventDefault();
                  props.previousStep?.();
                }}
              >
                {t("btnPrevious")}
              </button>
              <button className="btn btn-1 text-end" type="submit">
                {t("btnNext")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
