import React from "react";

export class ImageRadiobuttonGroup {
  name: string;
  selectedValue: string | undefined;
  updateSelectedValue: React.Dispatch<React.SetStateAction<string>>;
  required: boolean;

  constructor(
    name: string,
    required: boolean,
    initialState: [string, React.Dispatch<React.SetStateAction<string>>]
  ) {
    this.name = name;
    this.required = required;
    [this.selectedValue, this.updateSelectedValue] = initialState;
  }

  onCheck: (value: string) => void = (value) => {
    this.updateSelectedValue(value);
  };
}
